<template>
  <div>
    <div v-if="edit" class="edit-div">
      <img :src="preview" class="avatar" />
      <div class="change-avatar">
        <input
          type="file"
          v-on:change="imgPreview"
          accept="image/png, image/jpeg"
        />
        <button
          v-if="previewFile !== '' && fileError == ''"
          v-on:click="updateAvatar"
        >
          Upload avatar
        </button>
        <div class="file-error" v-if="fileError !== ''">{{ fileError }}</div>
      </div>
      <div class="form-container">
        <div class="double-form-element">
          <div>
            <label for="name">{{ $t("label.name") }}</label>
            <input type="text" id="name" v-model="name" required />
          </div>
          <div>
            <label for="surname">{{ $t("label.surname") }}</label>
            <input type="text" id="surname" v-model="surname" required />
          </div>
        </div>
        <div class="double-form-element">
          <div>
            <label for="email">{{ $t("label.email") }}</label>
            <input type="email" disabled :value="email" id="email" />
            <router-link to="/changeemail">
              {{ $t("label.change") }}
            </router-link>
          </div>
          <div>
            <label for="password">{{ $t("password.password") }}</label>
            <input type="password" value="0000000000" disabled />
            <router-link to="/changepassword">
              {{ $t("label.change") }}
            </router-link>
          </div>
        </div>
        <div class="double-form-element">
          <div>
            <label for="birthDay">{{ $t("label.birthdate") }}</label>
            <input type="date" id="birthDate" v-model="birthDate" required />
          </div>
          <div>
            <label for="phone">{{ $t("label.phone") }}</label>
            <input type="text" id="phone" v-model="phone" />
          </div>
        </div>
        <div class="double-form-element">
          <div>
            <label for="language">{{ $t("label.language") }}</label>
            <p class="select">
              <select id="language" v-model="language">
                <option value="en-US">English (US)</option>
                <option value="en-GB">English (GB)</option>
                <option value="fr-FR">Français</option>
              </select>
            </p>
          </div>
          <div></div>
        </div>
      </div>
      <div class="app-container">
        <div v-for="(service, index) in messageApp" :key="service.id">
          <label for="app" v-if="service.app != 'matrix'">App</label>
          <select id="app" v-model="service.app" v-if="service.app != 'matrix'">
            <option value="telegram" selected>Telegram</option>
            <option value="personal_matrix">Matrix (Element / Riot)</option>
          </select>
          <label for="identifier" v-if="service.app != 'matrix'">{{
            $t("label.identifier")
          }}</label>
          <input
            type="text"
            id="identifier"
            v-model="service.identifier"
            v-if="service.app != 'matrix'"
          />
          <button
            type="button"
            @click="openHelp(service.app)"
            class="help-button"
            v-if="service.app != 'matrix'"
          >
            ?
          </button>
          <button
            @click="messageApp.splice(index, 1)"
            class="remove-app-button"
            v-if="service.app != 'matrix'"
          >
            X
          </button>
        </div>
      </div>
      <button
        @click="messageApp.push({ app: '', identifier: '' })"
        class="add-app-button"
      >
        +
      </button>
      <br />
      <div class="buttons">
        <button
          id="confirmation-button"
          @click="submitEdit"
          :disabled="checkForm"
          class="save-button"
        >
          {{ $t("buttons.save") }}
        </button>
        <button id="back-button" @click="getBack" class="back-button">
          {{ $t("buttons.back") }}
        </button>
      </div>
    </div>
    <div v-if="!edit" class="display">
      <img :src="preview" class="avatar" />
      <div class="section">
        <h1>
          {{
            capitalizeFirstLetter(name) + " " + capitalizeFirstLetter(surname)
          }}
        </h1>
        <button
          class="edit-button"
          @click="toogleEdit"
          :style="{
            color: $store.getters.color.fontColor,
            backgroundColor: $store.getters.color.color1,
            borderColor: $store.getters.color.color1,
          }"
          v-if="
            this.$store.getters.userId == this.$route.params.id ||
            (this.$store.getters.tourAgencyId == this.tourAgency._id &&
              this.$store.getters.userType == 'tourAgencyEmployee')
          "
        >
          {{ $t("buttons.edit") }}
        </button>
      </div>
      <p>{{ email }}</p>
      <p v-if="birthDate != 'NaN-NaN-NaN'">
        🎂 {{ new Date(birthDate).toDateString() }} 🎂
      </p>
      <a
        :style="{ color: $store.getters.color.color3 }"
        :href="buildProfileLink()"
        @click.prevent="$router.push(buildProfileRoute())"
      >
        {{
          this.profile != ""
            ? this.$t("label.travelerProfile.travelerProfile")
            : this.$t("label.create", {
                object: this.$t("label.travelerProfile.travelerProfile"),
              })
        }}
      </a>
      <br />
      <hr />
      <p>{{ phone }}</p>
      <p>{{ $t("label.language") }}: {{ capitalizeFirstLetter(language) }}</p>
      <div>
        <div v-for="app in messageApp" :key="app.id">
          <span v-if="app.app != 'matrix'">{{
            app.app == "personal_matrix"
              ? "Matrix"
              : capitalizeFirstLetter(app.app)
          }}</span
          ><span v-if="app.app != 'matrix'">:</span>
          <span v-if="app.app != 'matrix'">{{ app.identifier }}</span>
        </div>
      </div>

      <div v-if="this.$store.getters.userType != 'client' && tourAgency">
        <br />
        {{ $t("label.agency") }}:
        <router-link
          :to="buildAgencyLink(tourAgency._id)"
          :style="{ color: $store.getters.color.color3 }"
        >
          {{ capitalizeFirstLetter(tourAgency.name) }}
        </router-link>
      </div>
      <br />
      <div>
        <mini-search
          :elements="
            travels.map((travel) => {
              return {
                label: travel.name,
                destination: buildLink(travel._id),
              };
            })
          "
          :title="$t('label.travel')"
          :color="{
            background: $store.getters.color.color2,
            font: $store.getters.color.fontColor,
            linkColor: $store.getters.color.color3,
          }"
          :minWidth="screenMinWidth(500)"
        ></mini-search>
        <br />
        <router-link
          :to="{
            name: 'travelForm',
            params: {
              tourAgency: tourAgency,
              agencyClients: [],
              client: {
                _id: $route.params.id,
                name: name,
                surname: surname,
                messageApp: messageApp,
              },
            },
          }"
          class="generate-form-link"
          :style="{ color: $store.getters.color.color3 }"
          v-if="
            (this.$store.getters.tourAgencyId == '' ||
              this.$store.getters.tourAgencyId == 'undefined') &&
            this.$store.getters.userId == this.$route.params.id
          "
        >
          {{ $t("buttons.new") }}
        </router-link>
      </div>
      <br />
      <div>
        {{ $t("label.voucher") }}:
        <ul>
          <li v-for="voucher in voucher" :key="voucher.id">
            <span class="voucher-code"
              >{{ $t("label.code") }}: {{ voucher.code.toUpperCase() }},</span
            >
            <span class="voucher-start-date">
              {{ new Date(voucher.startDate).toDateString() }} </span
            >-
            <span class="voucher-end-date">
              {{ new Date(voucher.endDate).toDateString() }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import miniSearch from "./miniSearch.vue";
import sdk from "matrix-js-sdk";

export default {
  name: "ClientDisplay",
  components: { miniSearch },
  data() {
    return {
      edit: false,
      name: "",
      surname: "",
      email: "",
      birthDate: "",
      password: "",
      passwordConfirmation: "",
      phone: "",
      messageApp: [],
      tourAgency: "",
      travels: [],
      voucher: [],
      language: "",
      profile: "",
      emailConfirmation: "",
      preview: "https://www.travelassist.io/assets/default-avatar.png",
      previewFile: "",
      previewType: "",
      previewName: "",
      avatarUrl: "",
      fileError: "",
    };
  },
  async created() {
    this.getInformation();
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.surname) {
        return true;
      }
      if (!this.birthDate) {
        return true;
      }
      return false;
    },
  },

  methods: {
    screenMinWidth(x) {
      return Math.min(x, window.screen.width - 20) + "px";
    },
    openHelp(app) {
      if (app == "telegram") {
        let routeData = this.$router.resolve({ name: "telegramhelp" });
        window.open(routeData.href, "_blank");
      }
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    niceDate: function (dat) {
      return (
        dat.getFullYear() + "-" + (dat.getMonth() + 1) + "-" + dat.getDate()
      );
    },
    getInformation: async function () {
      try {
        let information = {};
        if (this.$store.getters.userType === "contactCenterAgent") {
          information = await HandleApi.getClientInformationMinified(
            this.$route.params.id
          );
        } else {
          information = await HandleApi.getClientInformation(
            this.$route.params.id
          );
        }
        if (
          information.firstConnexion == true &&
          this.$store.getters.userId == this.$route.params.id
        ) {
          this.$router.push("/changepassword");
        }
        this.name = information.name;
        this.surname = information.surname;
        this.email = information.email;
        this.birthDate = this.niceDate(new Date(information.birthDate));
        if (information.hasOwnProperty("phone")) {
          this.phone = information.phone;
        }
        if (information.hasOwnProperty("messageApp")) {
          this.messageApp = information.messageApp;
        }
        this.tourAgency = information.tourAgency;
        if (information.hasOwnProperty("travel")) {
          this.travels = information.travel;
          for (let i = 0; i < this.travels.length; i++) {
            this.travels[i].name = this.travels[i].name
              .replace(/&amp;/gi, "&")
              .replace(/&#x27;/gi, "'");
          }
        }
        if (information.hasOwnProperty("voucher")) {
          this.voucher = information.voucher;
        }
        if (information.hasOwnProperty("language")) {
          this.language = information.language;
        }
        if (information.hasOwnProperty("profile")) {
          this.profile = information.profile;
        }
        if (information.hasOwnProperty("avatarLink")) {
          this.preview = information.avatarLink;
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    buildLink(travelId) {
      return "/travel/" + travelId;
    },
    buildAgencyLink(agencyId) {
      return "/agency/" + agencyId;
    },
    buildProfileLink() {
      if (this.profile != "") {
        return "/profile/" + this.profile;
      } else {
        return "/create/profile";
      }
    },
    buildProfileRoute() {
      if (this.profile != "") {
        return "/profile/" + this.profile;
      } else {
        return {
          name: "profileForm",
          params: { clientIdprop: this.$route.params.id },
        };
      }
    },
    toogleEdit: function () {
      this.edit = true;
    },
    getBack: function () {
      this.edit = false;
      this.getInformation();
    },
    submitEdit: async function () {
      try {
        let client_travel = [];
        for (let i = 0; i < this.travels.length; i++) {
          client_travel.push(this.travels[i]._id);
        }
        let newData = {
          name: this.name,
          surname: this.surname,
          birthDate: this.birthDate,
          phone: this.phone,
          messageApp: this.messageApp,
          travel: this.travels,
          language: this.language,
        };
        let res = await HandleApi.putClient(this.$route.params.id, newData);
        if (res.status == 200) {
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.updated", {
              object: this.$t("notification.profile"),
            })
          );
          this.$router.go("/client/" + this.$route.params.id);
        }
      } catch (err) {
        this.$store.dispatch("push_error_message", err);
      }
    },
    imgPreview: function (e) {
      const file = e.target.files[0];
      this.previewFile = file;
      this.previewType = file.type;
      this.previewName = file.name;
      if (file.size > 2097152) {
        this.fileError = "Votre avatar ne doit pas dépasser 2Mo";
      } else {
        this.fileError = "";
        this.preview = URL.createObjectURL(file);
      }
    },
    updateAvatar: async function () {
      if (this.fileError == "") {
        try {
          this.$store.dispatch("load", "Uploading your avatar");
          let res = undefined;
          res = await HandleApi.getMessageAuth(this.$store.getters.userId);
          let client = sdk.createClient({
            baseUrl: res.baseUrl,
            accessToken: res.accessToken,
            userId: res.userId,
          });
          let file = this.previewFile;
          let fileName =
            this.$store.getters.userId + "." + this.previewType.substring(6);
          this.avatarUrl = await client.uploadContent(file, {
            name: fileName,
            type: this.previewType,
          });
          let avatarUrl = this.avatarUrl.substring(6);
          this.avatarUrl =
            res.baseUrl + "_matrix/media/r0/download/" + avatarUrl;
          await client.setAvatarUrl(this.avatarUrl);
          await HandleApi.changeAvatar(this.$route.params.id, {
            avatarLink: this.avatarUrl,
          });
          this.preview = this.avatarUrl;
          this.$store.dispatch("unload");
        } catch (err) {
          this.$store.dispatch("push_error_message", err);
          this.$store.dispatch("unload");
        }
      }
    },
  },
};
</script>

<style scoped>
.edit-div {
  margin-top: 20px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-div label {
  margin-right: 10px;
  margin-left: 10px;
}

.error-message {
  border: 2px solid red;
  color: red;
  background-color: #ecb2b2;
  width: 500px;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 10px;
}

.app-container {
  display: inline-block;
}

.help-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  border: none;
  padding: 0;
}

.remove-app-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: #ff3a3a;
  border-radius: 30px;
  border: none;
  padding: 0;
}

.add-app-button {
  background-color: rgb(55, 187, 248);
  color: white;
  border: none;
  border-radius: 3px;
  height: 20px;
  font-weight: bold;
  width: 30px;
}

.save-button {
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}

.display {
  text-align: center;
  margin: 20px auto;
}

.display hr {
  width: 30%;
}

.display h1 {
  display: inline-block;
  margin-right: 10px;
}

.edit-button {
  box-shadow: 2px 2px 2px lightgrey;
  color: white;
  border: 2px solid #00909f;
  font-size: 17px;
  background-color: #00909f;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 7px;
  margin: auto 0;
}

.edit-button:hover {
  text-decoration: underline;
}

a {
  color: #206874;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.generate-form-link {
  background-color: #9ad3d1;
  color: white;
  font-weight: bold;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
}

.voucher-code {
  margin-right: 5px;
}

.voucher-start-date {
  margin-left: 5px;
  margin-right: 5px;
}

.voucher-end-date {
  margin-left: 0px;
}
.section {
  display: flex;
  justify-content: center;
}
.avatar {
  height: 128px;
  width: 128px;
  margin-bottom: 10px;
  border-radius: 50%;
}
.buttons {
  margin-bottom: 20px;
}

.change-avatar {
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-flow: space-around;
}

.file-error {
  padding: 0 5px;
  border-radius: 0.25em;
  background: rgba(255, 0, 0, 0.25);
}

/** Form css */
.form-container {
  width: 30%;
  margin: 10px auto 30px auto;
  padding: 0 5%;
}
@media (max-width: 1000px) {
  .form-container {
    width: 80%;
  }
}
.form-title {
  font-size: 110%;
  font-weight: bold;
}
.form-element {
  position: relative;
  margin: 20px 0;
}

.double-form-element {
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.double-form-element > div {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.double-form-element > div:nth-child(1) {
  align-self: flex-start;
  padding: 0 10px 0 0;
}
.double-form-element > div:nth-child(2) {
  align-self: flex-end;
  padding: 0 0 0 10px;
}
.form-element > input,
.double-form-element > div > input {
  width: 100%;
  display: block;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  height: 16px;
}
.form-element > input:disabled,
.double-form-element > div > input:disabled {
  background-color: #e5e5e5;
}
.form-element > input:focus,
.double-form-element > div > input:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.form-element > span {
  display: block;
}
.form-element > label,
.label,
.double-form-element > div > label {
  display: block;
  font-weight: bold;
  color: #666666;
}
/* Form list */
.form-list {
  display: inline-block;
  padding: 0;
  margin: 5px 0;
}
.form-list::after {
  clear: both;
  content: "";
  display: table;
}
.form-list > li {
  display: inline-block;
  position: relative;
  user-select: none;
  margin: 0 26px 16px 0;
  float: left;
}
/* Custom select element */
.double-form-element > div > p {
  margin: 5px 0;
}
.double-form-element > div > p > select {
  display: block;
  width: 100%;
  appearance: none;
}
.double-form-element > div > p > select {
  padding: 8px;
  border: 1px solid #cfd9db;
  background-color: white;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.double-form-element > div > p > select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 141, 222, 0.2);
}
.double-form-element > div > .select {
  display: inline-block;
  width: 100%;
  position: relative;
}
.double-form-element > div > .select::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Cpolygon%20fill%3D%22%232c3e50%22%20points%3D%220.9%2C5.5%203.1%2C3.4%208%2C8.3%2012.9%2C3.4%2015.1%2C5.5%208%2C12.6%20%09%22%2F%3E%0D%0A%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E")
    no-repeat center center;
  pointer-events: none;
}
.double-form-element > div > p > select {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.double-form-element > div > p > select::-ms-expand {
  display: none;
}
/* Custom Radio & checkbox Button */
.form-list input[type="radio"],
.form-list input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.form-list input[type="radio"] + label,
.form-list input[type="checkbox"] + label {
  padding-left: 24px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after {
  border-radius: 50%;
}
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.form-list input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="radio"]:focus + label::before,
.form-list input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::after {
  display: block;
}
.form-list input[type="radio"]:checked + label::before,
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::before,
.form-list input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}

@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
/** End of form css */
</style>
